import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.href = "https://yatter.in";
  }, []);

  return (
    <div className="outer-container">
      <p>YatterPlus is now rebranded to Yatter.</p>
      <h3>Redirecting you to yatter.in now . . .</h3>
    </div>
  );
}

export default App;
